import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Stack,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Dialog,
} from "@mui/material";
import FormBox from "../../FormBox/FormBox ";
import Pagination_ from "../../Pagination/Pagination";
import { CardExersiceContext } from "../../../Contexts/CardsExesciseContext";
import { useNavigate } from "react-router-dom";
import Treemenu from "../../Treemenu/Treemenu";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SearchIcon from "@mui/icons-material/Search";
import { HOST_NAME } from "../../../config";
import { setHeaders } from "../../../utils";
import axios from "axios";
import { Add } from "@mui/icons-material";
import ExerciseModal from "./ExerciseModal";

export default function Personalization() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowTreemenu, setIsShowTreemenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [allCustomizedData, setAllCustomizedData] = useState([]);
  const [treeMenuDataExerciseCartData, setTreeMenuDataExerciseCartData] =
    useState([]);

  const {
    keyIdItemsCardExersice,
    currentPage,
    setCountPageNation,
    isShowAlertError,
    setIsShowAlertError,
  } = useContext(CardExersiceContext);

  useEffect(() => {
    getAllCustomizedData(keyIdItemsCardExersice, currentPage);
  }, [keyIdItemsCardExersice, currentPage]);

  useEffect(() => {
    getAllTreeMenuDataExerciseCartDatas();
  }, []);

  useEffect(() => {
    if (treeMenuDataExerciseCartData.length === 0) {
      setIsShowAlertError(true);
    } else {
      setIsShowAlertError(false);
    }
  }, [treeMenuDataExerciseCartData]);

  function getAllCustomizedData(keyID, pagenumber) {
    const headers = setHeaders();

    const pageSize = 10;
    setIsLoading(true);

    const keyIDArray = Array.isArray(keyID) ? keyID : [keyID];
    const keyIDString = keyIDArray.join(" ");

    axios
      .get(
        `${HOST_NAME}/api/v1/Exercise/GetCustomizedBYpage/${
          keyIDString ? keyIDString : "-1"
        },${pagenumber},${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAllCustomizedData(res.data.exerciseslist);
          setIsLoading(false);
          setCountPageNation(Math.ceil(res.data.totalcount / pageSize));
        }
      })
      .catch((e) => console.log(e));
  }

  function getAllTreeMenuDataExerciseCartDatas() {
    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/ExerciseCtg`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          const treeMenuDataWithShowAll = [
            {
              keyID: -1,
              ctgName: "نمایش همه",
              fK_ParentID: null,
            },
            ...res.data,
          ];

          setTreeMenuDataExerciseCartData(treeMenuDataWithShowAll);
        }
      })
      .catch((err) => console.log("err", err));
  }

  return (
    <>
      <div className="container-fluid">
        <div className="col-12 main-section__tabOne">
          {/* {isShowTreemenu && (
            <div className="col-3 section-treemenu-tab1">
              <Treemenu
                pageUsed="exercises"
                data={treeMenuDataExerciseCartData}
              />
            </div>
          )} */}

          {allCustomizedData ? (
            <Stack
              spacing={2}
              direction="rtl"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100vh",
                overflowY: "hidden",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <PerfectScrollbar style={{ overflowX: "hidden" }}>
                <div style={{ width: "100%", margin: "1%" }}>
                  <Paper
                    className="section-header-tab1"
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      font: "IRANSans",
                    }}
                  >
                    <InputBase
                      sx={{ m: "0 10px", flex: 1, fontSize: 14 }}
                      placeholder="جست و جو ورزش ها ... "
                      inputProps={{ "aria-label": "search google maps" }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Paper>

                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        margin: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <div className="container section-cardExersice">
                        <div
                          className="row"
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "16px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <FormBox
                              isCustomizedTab
                              onRemoveExercise={() =>
                                getAllCustomizedData(
                                  keyIdItemsCardExersice,
                                  currentPage
                                )
                              }
                              data={allCustomizedData}
                              isShowTreemenu={isShowTreemenu}
                              isShowAlertError={isShowAlertError}
                            />
                          </Grid>
                        </div>
                      </div>

                      {!isLoading && <Pagination_ />}
                    </>
                  )}
                </div>
              </PerfectScrollbar>
            </Stack>
          ) : null
          // <div style={{ width: "100%", margin: "1%" }}>
          //   <Paper
          //     className="section-header-tab1"
          //     component="form"
          //     sx={{
          //       p: "2px 4px",
          //       display: "flex",
          //       alignItems: "end",
          //       width: "100%",
          //       font: "IRANSans",
          //     }}
          //   >
          //     <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          //     <div className="section-header-tab1-show-categry">
          //       <IconButton
          //         color="primary"
          //         sx={{ p: "10px" }}
          //         aria-label="directions"
          //       >
          //         <AccountTreeIcon
          //           fontSize="large"
          //           onClick={() =>
          //             setIsShowTreemenu((prevState) => !prevState)
          //           }
          //         />
          //       </IconButton>
          //     </div>
          //     <IconButton
          //       type="button"
          //       sx={{ p: "10px" }}
          //       aria-label="search"
          //     >
          //       <SearchIcon fontSize="large" />
          //     </IconButton>

          //     <InputBase
          //       sx={{ ml: 1, flex: 1 }}
          //       placeholder="جست و جو ورزش ها ... "
          //       inputProps={{ "aria-label": "search google maps" }}
          //     />
          //   </Paper>

          //   {isLoading ? (
          //     <Box
          //       sx={{
          //         display: "flex",
          //         justifyContent: "center",
          //         alignContent: "center",
          //         margin: "1rem",
          //       }}
          //     >
          //       <CircularProgress />
          //     </Box>
          //   ) : (
          //     <>
          //       <div className="container section-cardExersice">
          //         <div
          //           className="row"
          //           style={{
          //             width: "100%",
          //             display: "flex",
          //             flexDirection: "row",
          //             gap: "16px",
          //           }}
          //         >
          //           <Grid container spacing={2}>
          //             <FormBox
          //               isCustomizedTab
          //               data={allCustomizedData}
          //               isShowTreemenu={isShowTreemenu}
          //               isShowAlertError={isShowAlertError}
          //             />
          //           </Grid>
          //         </div>
          //       </div>

          //       <Pagination_ />
          //     </>
          //   )}
          // </div>
          }
        </div>
      </div>
    </>
  );
}
