import React, { useState, useEffect } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";

import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Stack,
} from "@mui/material";
import ModalExcer from "./ModalExcer.jsx";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IoIosAttach } from "react-icons/io";
import { MdOutlineStorage } from "react-icons/md";
import { VscGroupByRefType } from "react-icons/vsc";

import { HOST_NAME } from "../../config.js";

import { setHeaders } from "../../utils.js";
import Swal from "sweetalert2";
import { MdPermMedia } from "react-icons/md";

import "./ManageExercise.css";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import MuiRTL from "../../Components/MuiRTL/MuiRTL.js";
import ModalAttachExeFile from "./ModalAttachExeFile.jsx";
import Modalsetparameters from "./ModalSetParameter.jsx";
import ModalGroup from "./ModalGroup.jsx";

export default function ManageExercise() {
  const [exerciseList, setexerciseList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editingexerciseId, setEditingexerciseId] = useState(null);
  const [isAttachModalOpen, setAttachModalOpen] = useState(false);
  const [AttachModalID, setAttachEduId] = useState(false);
  const [isParameterModalOpen, setParameterModalOpen] = useState(false);
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);

  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [userInfo, setUserInfo] = useState(null);

  useState(() => {
    const headers = setHeaders();
    axios
      .get(`${HOST_NAME}/api/v1/Physician/GetLoginedInfoByuserID`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setUserInfo(res.data);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    refreshlist();
  }, [currentPage]);

  function refreshlist() {
    setIsLoading(true);
    const headers = setHeaders();
    const pageSize = 8;
    axios
      .get(
        `${HOST_NAME}/api/v1/Exercise/GetMyExercise/-1,${currentPage},${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setexerciseList(res.data.exerciseslist);
          setCountPage(Math.ceil(res.data.totalcount / pageSize));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function handleEditClick(exerciseId) {
    setEditingexerciseId(exerciseId);
    setEditModalOpen(true);
  }

  function handleAttachModal(exerciseId) {
    setAttachEduId(exerciseId);
    setAttachModalOpen(true);
  }

  function handleParametersModal(exerciseId) {
    setParameterModalOpen(true);
    setEditingexerciseId(exerciseId);
  }

  function handleGroupingModal(exercise) {
    setGroupModalOpen(true);
    setSelectedExercise(exercise);
  }

  function removeExercise(exerciseId) {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف این تمرین مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "لغو",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(`${HOST_NAME}/api/v1/exercise/${exerciseId}`, { headers })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "تمرین با موفقیت حذف شد",
              });
              refreshlist();
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error.response.data,
            });
          });
      }
    });
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <ResponsiveNavbar />
      <>
        <div className="management-container">
          <h1>مدیریت تمرین های من</h1>
          <div className="management-actions">
            <Button onClick={() => handleEditClick(0)} variant="contained">
              افزودن
              <AddCircleOutlineIcon className="management-add-icon" />
            </Button>
          </div>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                margin: "1rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            exerciseList.length > 0 && (
              <>
                <table>
                  <thead>
                    <tr className="text-sm">
                      <th>شناسه</th>
                      <th>عکس</th>
                      <th>عنوان</th>
                      <th>عملیات</th>
                    </tr>
                  </thead>

                  <tbody>
                    {exerciseList.map((exercise) => (
                      <tr key={exercise.keyID}>
                        <td>{exercise.keyID}</td>
                        <td>
                          <img
                            src={
                              exercise.mainPicturePath
                                ? exercise.mainPicturePath
                                : "/images/default.png"
                            }
                            alt=""
                          />
                        </td>
                        <td>{exercise.name}</td>
                        <td className="actions">
                          <div>
                            <button
                              onClick={() => handleEditClick(exercise.keyID)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              onClick={() => handleAttachModal(exercise.keyID)}
                            >
                              <MdPermMedia />
                            </button>
                            <button
                              onClick={() =>
                                handleParametersModal(exercise.keyID)
                              }
                            >
                              <MdOutlineStorage />
                            </button>

                            <button
                              onClick={() => handleGroupingModal(exercise)}
                            >
                              <VscGroupByRefType />
                            </button>
                            <button
                              onClick={() => removeExercise(exercise.keyID)}
                              title="Delete"
                            >
                              <FaTrashAlt />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <MuiRTL>
                  <Stack
                    spacing={2}
                    direction="rtl"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    <Pagination
                      count={countPage}
                      page={currentPage}
                      color="primary"
                      onChange={handlePageChange}
                    />
                  </Stack>
                </MuiRTL>
              </>
            )
          )}
        </div>

        {isEditModalOpen && (
          <ModalExcer
            exerciseLimit={userInfo.exerciseLimit}
            refresh={refreshlist}
            isOpen={isEditModalOpen}
            onClose={() => setEditModalOpen(false)}
            Idd={editingexerciseId}
          />
        )}

        {isAttachModalOpen && (
          <ModalAttachExeFile
            exerFileLimit={userInfo.exerFileLimit}
            isOpen={isAttachModalOpen}
            onClose={() => setAttachModalOpen(false)}
            Idd={AttachModalID}
            refresh={refreshlist}
          />
        )}
        {isParameterModalOpen && (
          <Modalsetparameters
            isOpen={isParameterModalOpen}
            onClose={() => setParameterModalOpen(false)}
            Idd={editingexerciseId}
          />
        )}

        {isGroupModalOpen && (
          <ModalGroup
            isOpen={isGroupModalOpen}
            onClose={() => setGroupModalOpen(false)}
            content={selectedExercise}
            getCategoriesLink="ExerciseCtg/GetCategoriesByExerID?ExerciseID="
            setCategoriesLink="Exercise/SetCategories"
            treeLink="ExerciseCtg"
            field="exerciseID"
          />
        )}
      </>
    </>
  );
}
