import React, { useState, useEffect, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Android12Switch,
  isUserAdmin,
  parseJwt,
  setHeaders,
} from "../../utils";
import { FormGroup, FormControlLabel, Button } from "@mui/material";
import CameraswitchOutlinedIcon from "@mui/icons-material/CameraswitchOutlined";

import ImageAvatars from "../ImageAvatar/ImageAvatar";
import VideoModal from "../VideoModal/VideoModal";

import "./CaptionFormCard.css";
import TextFieldwithAddIcon from "../TextFildwithAddIcon/TextFildwithAddIcon";
import TextEditor from "../TextEditor/TextEditor";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function CaptionFormCard({ keyID, isCustomizedTab }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [switchPersonalization, setSwitchPersonalization] = useState(false);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [parameters, setParameters] = useState(null);
  const [detailCardExerciseData, setDetailCardExerciseData] = useState({});
  const [fetchedData, setFetchedData] = useState(null);
  const [decodedUser, setDecodedUser] = useState({});
  const [videos, setVideos] = useState([]);
  const [exerciseFiles, setExerciseFiles] = useState([]);
  const [dataReceived, setDataReceived] = useState(false);
  const [commands, setCommands] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [secondaryLink, setSecondaryLinks] = useState(null);
  const [customizedFetch, setCustomizedFetch] = useState(null);

  const handleMediaClick = (media) => {
    setCurrentMedia(media);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentMedia([]);
  };

  const localStorageData = localStorage.getItem("user");

  const getInformationPopupCard = (keyID) => {
    const headers = setHeaders();

    const api =
      (!isUserAdmin(["superadmin", "mainadmin"]) && switchPersonalization) ||
      isCustomizedTab
        ? `Getcustomized/${keyID}`
        : keyID;

    axios
      .get(`${HOST_NAME}/api/v1/Exercise/${api}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setFetchedData(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    isCustomizedTab &&
      axios
        .get(`${HOST_NAME}/api/v1/Exercise/${keyID}`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setCustomizedFetch(res.data);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
  };

  useEffect(() => {
    if (isCustomizedTab) {
      customizedFetch &&
        setDetailCardExerciseData({
          ...fetchedData,
          links: customizedFetch.links,
        });
    } else {
      fetchedData && setDetailCardExerciseData(fetchedData);
    }
  }, [fetchedData, customizedFetch]);

  useEffect(() => {
    setDecodedUser(parseJwt(localStorageData));
  }, []);

  useEffect(() => {
    getInformationPopupCard(keyID);
  }, [decodedUser, switchPersonalization, isSaved]);

  const {
    execution,
    links,
    mainPicturePath,
    name,
    otherDescription,
    preparation,
    subject1,
    subject2,
  } = detailCardExerciseData;

  const getDataOfLinks = (link, setData) => {
    const headers = setHeaders();

    axios
      .get(link, {
        headers,
      })
      .then((res) => {
        res.status === 200 && setData(res.data);
      })
      .catch((err) => console.log("err", err));
  };
  const [videoLink, setVideoLink] = useState("");
  const [paremeterLink, setparemeterLink] = useState("");

  useEffect(() => {
    if (links) {
      const videoLinkFetch = links[0].href;
      const exerciseFilesLinkFetch = links[1].href;
      const exerciseParametersLinkFetch = links[2].href;
      setSecondaryLinks(links[2].href);
      setVideoLink(videoLinkFetch);
      switchPersonalization || isCustomizedTab
        ? setparemeterLink(
            `${HOST_NAME}/api/v1/Exercise/${keyID}/ExerciseParameters/getCustomized`
          )
        : setparemeterLink(exerciseParametersLinkFetch);

      getDataOfLinks(videoLinkFetch, setVideos);

      getDataOfLinks(exerciseFilesLinkFetch, setExerciseFiles);
    }
  }, [links, switchPersonalization]);

  useEffect(() => {
    (exerciseFiles.length > 0 || videos.length > 0) && setDataReceived(true);
  }, [exerciseFiles, videos]);

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ align: [] }],
      [{ direction: "rtl" }],
    ],
  };

  const formats = [
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "align",
    "direction",
  ];

  function handleSubmitParameters() {
    const dataToSend = parameters.map(
      ({ paramName, paramValue, isDefault, keyID }) => ({
        IDkey: keyID,
        paramName,
        paramValue,
        paramType: "string",
        isDefault,
      })
    );

    const headers = setHeaders();

    axios
      .post(
        `${HOST_NAME}/api/v1/Exercise/${keyID}/ExerciseParameters/updateCustomized`,
        dataToSend,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setCommands([]);
          setIsSaved(true);

          setTimeout(() => setIsSaved(false), 1000);
        }
      })
      .catch((error) => {
        console.error("Error sending parameters:", error);
      });
  }

  function handleSubmitContent() {
    const headers = setHeaders();

    const newContent = {
      ...textEditorObject,
      keyID,
      name,
      exerciseCategoryID: 0,
    };

    axios
      .put(
        `${HOST_NAME}/api/v1/Exercise/updateCustomized?iscustom=true`,
        newContent,
        {
          headers,
        }
      )

      .catch((error) => {
        console.error("Error sending content:", error);
      });
  }

  function addToCustomized() {
    handleSubmitParameters();
    handleSubmitContent();
  }

  function extractContentFromUl(contentUl) {
    if (contentUl && contentUl.tagName === "UL") {
      const liItems = contentUl.querySelectorAll("li");
      return Array.from(liItems)
        .map((li) => li.textContent)
        .join("\n");
    }
  }

  function extractDataFromHTML(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const titles = doc.querySelectorAll("p");
    const subject1 = titles[0].textContent;
    const subject2 = titles[1].textContent;
    const preparation = extractContentFromUl(titles[0].nextElementSibling);
    const execution = extractContentFromUl(titles[1].nextElementSibling);

    return { subject1, subject2, preparation, execution, otherDescription };
  }

  let textEditorObject = {
    subject1,
    subject2,
    preparation,
    execution,
    otherDescription,
  };

  function handleEditorChange(content) {
    textEditorObject = extractDataFromHTML(content);
  }

  const textEditorProps = {
    subject1,
    subject2,
    preparation,
    execution,
    otherDescription,
    detailCardExerciseData,
    switchPersonalization: isCustomizedTab ? true : switchPersonalization,
    reactQuillProps: {
      modules,
      formats,
      onChange: handleEditorChange,
    },
  };

  return (
    <div className="caption-card row">
      {/* section 1 */}
      <div className="section-header-caption-cardExersice">
        <div className="avtar-header-caption-cardExersice">
          <ImageAvatars fontSize="large" imgSrc={mainPicturePath} />
          <p
            style={{
              fontFamily: "IRANSans",
              padding: "5px",
              fontSize: "1.3rem",
            }}
          >
            {name}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            disabled
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <CameraswitchOutlinedIcon fontSize="large" />
          </button>
          {!isUserAdmin(["superadmin", "mainadmin"]) && !isCustomizedTab && (
            <>
              <span style={{ fontSize: "1.2rem" }}>شخصی‌سازی:</span>
              <FormGroup>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Android12Switch
                      defaultChecked={switchPersonalization}
                      onChange={() =>
                        setSwitchPersonalization((prevState) => !prevState)
                      }
                    />
                  }
                />
              </FormGroup>
            </>
          )}
          {(switchPersonalization || isCustomizedTab) && (
            <div>
              <Button
                onClick={addToCustomized}
                style={{
                  backgroundColor: "#5eca22",
                  lineHeight: "1.5rem",
                  margin: "10px",
                }}
              >
                {isSaved ? "ذخیره شد" : "ذخیره"}
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* section 3 Form information */}
      <TextFieldwithAddIcon
        isCustomizedTab={isCustomizedTab}
        readonly={!switchPersonalization}
        setParameters={setParameters}
        commands={commands}
        setCommands={setCommands}
        link={paremeterLink}
        secondaryLink={secondaryLink}
        keyID={keyID}
        isSaved={isSaved}
      />

      {/* section 4 Detail*/}
      <TextEditor {...textEditorProps} />

      {/* section 5 video and images*/}
      <div className="container-fluid section-video-image-caption-cardExersice">
        <div>
          <Button
            className="show-video-btn"
            onClick={() => handleMediaClick(exerciseFiles)}
          >
            نمایش تصاویر و ویدیو ها
          </Button>

          {dataReceived ? (
            <VideoModal
              isOpen={modalOpen}
              handleClose={handleCloseModal}
              media={currentMedia}
            />
          ) : (
            <p style={{ color: "red", fontFamily: "IRANSans" }}>
              هیچ تصویر و ویدویی برای نمایش وجود ندارد .
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
