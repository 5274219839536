import React, { useContext, useEffect, useState } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import "./Basket.css";

import { SubscriptionContext } from "../../Contexts/Subscriptions";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { Link, useNavigate } from "react-router-dom";
import {
  isAuthenticated,
  isUserAdmin,
  isUserRoleActive,
  setHeaders,
} from "../../utils";
import Loader from "../../Components/Loader/Loader";
import Swal from "sweetalert2";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function Basket() {
  const navigate = useNavigate();
  const {
    choosedSubscriptions,
    setChoosedSubscriptions,
    orderKeyID,
    setOrderKeyID,
    hasActiveOrder,
    setHasActiveOrder,
  } = useContext(SubscriptionContext);

  const [initialAmount, setInitialAmount] = useState(0);
  const [discountInput, setDiscountInput] = useState("");
  const [discountInfo, setDiscountInfo] = useState(null);
  const [initialDiscount, setInitialDiscount] = useState(0);
  const [discountCost, setDiscountCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [orderInformation, setOrderInformation] = useState(null);

  useEffect(() => {
    const price = choosedSubscriptions[0]?.price;
    const discount = choosedSubscriptions[0]?.discount;

    setInitialAmount(calculateDiscountedPrice(price, discount));
    setTotalPrice(calculateDiscountedPrice(price, discount));
  }, [choosedSubscriptions]);

  useEffect(() => {
    if (initialDiscount) {
      setDiscountCost((initialDiscount / 100) * initialAmount);
      setTotalPrice(calculateDiscountedPrice(initialAmount, initialDiscount));
    }
  }, [initialDiscount]);

  function calculateDiscountedPrice(price, discount) {
    const discountTotal = (discount / 100) * price;

    return price - discountTotal;
  }

  function purchase() {
    const headers = setHeaders();

    const orderID = localStorage.getItem("value");

    axios
      .post(
        `${HOST_NAME}/api/v1/Payment?orderKeyID=${orderID}&paymentPrice=${totalPrice}`,
        null,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const { result, token } = res.data;
          if (result === "ok: رایگان فعال شد") {
            setHasActiveOrder(true);
            setChoosedSubscriptions([]);
            navigate("/payverify?Status=free");
            localStorage.setItem("user", JSON.stringify({ token }));
          } else {
            window.location.href = result;
          }
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          Swal.fire({
            icon: "error",
            title: "نمیتوان 2 بار از اشتراک رایگان استفاده کرد",
          });
        }
        console.error("Error:", error.status);
      });
  }

  function applyOrder() {
    if (isUserAdmin(["Admin"])) {
      Swal.fire({
        icon: "warning",
        title: "شما اشتراک فعال دارید",
        text: "تا زمانی که اشتراک فعال دارید نمیتوانید اشتراک دیگری خریداری کنید. در صورت تمایل میتوانید اشتراک خود را تغییر دهید.",
      });
    } else {
      const headers = setHeaders();

      const orderData = {
        keyID: 0,
        fK_ServiceId: choosedSubscriptions[0]?.keyID,
        fK_DiscountId: discountInfo?.keyId || 0,
        authorityCode: "string",
        refId: 0,
        orderState: 0,
        transactionCode: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      };

      axios
        .post(`${HOST_NAME}/api/v1/Order`, orderData, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setOrderKeyID(res.data);
              localStorage.setItem("value", res.data);
              purchase();
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function applyDiscount() {
    const headers = setHeaders();

    setIsApplyingDiscount(true);

    axios
      .get(`${HOST_NAME}/api/v1/Discount/GetByCode?code=${discountInput}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          const { keyId, discountPercent } = response.data;

          if (discountInfo?.keyId !== keyId) {
            setDiscountInfo({ keyId, discountPercent });
            setInitialDiscount((prevState) => prevState + discountPercent);
          } else {
            Swal.fire({
              icon: "warning",
              title: "این کد تخفیف از قبل اعمال شده",
            });
          }
          setDiscountInput("");
        }
      })
      .catch((error) => {
        Swal.fire({ icon: "error", title: "کد تخفیف معتبر نیست" });
      })
      .finally(() => {
        setIsApplyingDiscount(false);
      });
  }

  function getOrderInformation(orderID) {
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;

      fetch(`${HOST_NAME}/api/v1/Order/${orderID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setOrderInformation(res.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <>
      <ResponsiveNavbar />

      <div className="basket-container ">
        {choosedSubscriptions.length > 0 ? (
          <div className="basket-main-section">
            <div className="right">
              <div className="header">
                <LocalMallOutlinedIcon />
                <span>سبد خرید</span>
              </div>

              <div className="body">
                {choosedSubscriptions.map(
                  ({ subject, price, discount, image }) => (
                    <div key={subject} className="basket-subscription">
                      <div>
                        <img src={image} alt="subscription" />
                        <h2>{subject}</h2>
                      </div>
                      <div>
                        <div>
                          {discount ? (
                            <div className="price-discount">
                              <span className="old-price">
                                {price
                                  ? `${price.toLocaleString()} تومان`
                                  : "رایگان"}
                              </span>
                              <div className="current-price">
                                {calculateDiscountedPrice(
                                  price,
                                  discount
                                ).toLocaleString()}{" "}
                                <span className="toman">تومان</span>
                              </div>
                            </div>
                          ) : price ? (
                            <div className="current-price">
                              {price.toLocaleString()}{" "}
                              <span className="toman">تومان</span>
                            </div>
                          ) : (
                            <div className="current-price">رایگان</div>
                          )}
                        </div>
                        <DeleteOutlineOutlinedIcon
                          className="basket-remove-btn"
                          onClick={() =>
                            setChoosedSubscriptions((prevState) =>
                              prevState.filter((sub) => sub.subject !== subject)
                            )
                          }
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="left">
              <div className="left-top">
                <div className="header">
                  <CreditCardOutlinedIcon />
                  <span>اطلاعات پرداخت</span>
                </div>
                <div className="body">
                  <div className="row">
                    <span>مبلغ کل</span>
                    <span>{initialAmount.toLocaleString()}</span>
                  </div>
                  {initialDiscount > 0 && (
                    <div className="row discount">
                      <span>تخفیف</span>
                      <span>
                        ({initialDiscount}%) {discountCost.toLocaleString()}{" "}
                        تومان
                      </span>
                    </div>
                  )}
                </div>
                <div className="footer">
                  <div className="row total">
                    <span>قابل پرداخت:</span>
                    <span>{totalPrice.toLocaleString()} تومان</span>
                  </div>

                  {isAuthenticated() ? (
                    <button onClick={applyOrder} className="basket-submit-btn">
                      تکمیل خرید
                    </button>
                  ) : (
                    <Link to="/login" className="basket-submit-btn">
                      ورود و ادامه
                    </Link>
                  )}
                </div>
              </div>
              {isAuthenticated() && (
                <div className="left-bottom">
                  <div className="header">
                    <LocalOfferOutlinedIcon />
                    <span>کد تخفیف دارید؟</span>
                  </div>
                  <div className="body">
                    <input
                      type="text"
                      value={discountInput}
                      onChange={(e) => setDiscountInput(e.target.value)}
                      placeholder="کد تخفیف را وارد کنید"
                    />

                    <button onClick={applyDiscount}>
                      {isApplyingDiscount ? <Loader noText /> : "اعمال تخفیف"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="empty-basket-container">
            <img src="/images/empty-basket.png" alt="empty-basket" />
            <p>سبد خرید شما خالی است. برای مشاهده لیست اشتراک ها کلیک کنید</p>
            <Link to="/subscriptions">لیست اشتراک ها</Link>
          </div>
        )}
      </div>
    </>
  );
}
